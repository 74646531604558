<template>
<navi-bar>
  {{$t('front.common.board')}}
</navi-bar>
  <section class="sub-box">
    <board-filter-mobile @onSearch="onSearch"/>
    <div class="writebtn">
      <a class="btno" @click="onClickWrite">{{$t('front.board.boardWrite')}}</a>
    </div>
    <div class="msgwrap">
      <div class="freeboardtop"><span>{{$t('front.board.alert')}}</span>{{$t('front.board.pointCheck')}}</div>
      <template v-if="isView">
        <template v-for="item in noticeList" v-bind:key="item.boardIdx">
          <ul class="notice">
            <li>
              <a @click="onClickRead(item.boardIdx)">
                <span class="subject"><em class="noticeicon">{{$t('front.board.guide')}}</em><em class="subjecttxt1">{{item.title}}</em><em class="newicon">N</em></span>
                <span class="name"><img src="@/assets/img/play_double.svg" /></span>
              </a>
            </li>
          </ul>
        </template>

        <template v-for="item in eventList" v-bind:key="item.boardIdx">
          <ul class="event">
            <li>
              <a @click="onClickRead(item.boardIdx)">
                <span class="subject"><em class="eventicon">{{$t('front.board.event')}}</em><em class="subjecttxt1">{{item.title}}</em><em class="newicon">N</em></span>
                <span class="name"><img src="@/assets/img/play_double.svg" /></span>
              </a>
            </li>
          </ul>
        </template>

        <template v-for="item in list" v-bind:key="item.boardIdx">
          <ul class="off">
            <li>
              <a @click="onClickRead(item.boardIdx)">
                <span class="subject"><em class="subjecttxt1">{{item.title}}</em><em class="newicon">N</em></span>
                <span class="date" :class="getMedalClassMobile(item.memLevel)"><em class="servicename">{{item.memName}}</em>{{dateFormatForMobile(item.regDate)}}</span>
              </a>
              <span class="comment" v-if="item.comment.length > 0">{{item.comment.length}}<span>{{$t('front.board.comment')}}</span></span>
            </li>
          </ul>
        </template>
      </template>

    </div>
    <pagination-mobile v-if="pageInfo"
                       :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="pageMoveMobile"
    />
  </section>
</template>

<script>

import Board from '@/views/member/board/list'
import PaginationMobile from '@/components/ui/PaginationMobile'
import NaviBar from '@/components/common/mobile/NaviBar'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'

export default {
  name: 'board',
  components: { BoardFilterMobile, NaviBar, PaginationMobile },
  mixins: [Board]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped>
.searchwrap {margin-bottom: 10px !important;}
.morebtn {margin-bottom: 100px !important;}
</style>
